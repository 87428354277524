import { gql } from "@apollo/client"

export const CREATE_AUTHENTICATION = gql`
    mutation CreateAuthentication($action: AuthenticationAction!) {
        createAuthentication(action: $action) {
            code
            lnUrl
        }
    }
`

export const EXCHANGE_AUTHENTICATION = gql`
    mutation ExchangeAuthentication($code: String!) {
        exchangeAuthentication(code: $code) {
            token
        }
    }
`

export const VERIFY_AUTHENTICATION = gql`
    query VerifyAuthentication($code: String!) {
        verifyAuthentication(code: $code) {
            verified
        }
    }
`
