import {
    API_CREATE_AUTHENTICATION,
    API_LOGGED_IN,
    API_LOGGED_OUT
} from "./actions"

const initialState = {
    isLoggedIn: false,
    code: null,
    qrCode: null
}

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_CREATE_AUTHENTICATION:
            return {
                ...state,
                code: action.createAuthentication.code,
                qrCode: `lightning:${action.createAuthentication.lnUrl}`
            }
        case API_LOGGED_IN:
            return { ...state, isLoggedIn: true, code: null, qrCode: null }
        case API_LOGGED_OUT:
            return { ...state, isLoggedIn: false }
        default:
            return state
    }
}

export { initialState }
export default apiReducer
